body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#features::-webkit-scrollbar {
  display: none;
}

#features {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.text-ffc0c6 {
  color: #ffc0c6;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, rgba(255, 192, 198, 0.2), white);
}
